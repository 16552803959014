@import "./styles/_styles.scss";
.articlePageWrap {
  position: relative;
  :global {
    .builder-block {
      margin-top: 0 !important;
    }
    .builder-block > .builder-text {
      text-align: left !important;
      margin: 0 auto !important;
      padding: 32px 0 !important;
      max-width: 100% !important;
      width: 870px !important;
      @media screen and(max-width:768px) {
        padding: 20px 0 !important;
        width: 533px !important;
      }
      @media screen and(max-width:576px) {
        padding: 20px 15px !important;
      }
    }
  }
}
